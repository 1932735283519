import reportWebVitals from './reportWebVitals';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

/** Presentational */
import App from './Components/App';
import {GlobalStyles} from './global';

/** Service worker */
import * as serviceWorker from './serviceWorker';

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-5JNHTP3'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
    <>
            <GlobalStyles/>
            <App/>
    </>,
    document.getElementById('root')
);

reportWebVitals();

serviceWorker.unregister();
