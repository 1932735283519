import React, { lazy } from "react";
import { withRouter } from 'react-router';

import IntroContent from "../content/IntroContent.json";
import AboutContent from "../content/AboutContent.json";

import Footer from "../Components/Footer";
import Header from "../Components/Header";

import GlobalStyles from "../globalStyles";


const ContentBlock = lazy(() =>
  import ("../Components/ContentBlock"));
const Container = lazy(() =>
  import ("../common/Container"));


const PublicHomeContainer = () => {

  return (
    <React.Fragment>
    <GlobalStyles />
      <Header />
    <Container>
      <ContentBlock
        type="right"
        first="true"
        title={IntroContent.title}
        content={IntroContent.text}
        button={IntroContent.button}
        icon="developer.svg"
        id="intro"
      />
      <ContentBlock
        type="left"
        title={AboutContent.title}
        content={AboutContent.text}
        section={AboutContent.section}
        icon="graphs.svg"
        id="about"
      />
    </Container>
    <Footer />
    </React.Fragment>
  );
};

export default withRouter(PublicHomeContainer);
