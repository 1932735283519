import * as React from 'react';

/** Components */
import AppRouter from '../Navigation/Routes';

/** Ant design stylesheet */
import 'antd/dist/antd.css';


const App = () => {
    return <AppRouter />;
};

export default App;
