import React, {lazy} from "react";
import {withRouter} from 'react-router';

import Footer from "../Components/Footer";
import Header from "../Components/Header";



const Container = lazy(() =>
    import ("../common/Container"));


const DatenschutzerklärungContainer = () => {

    return (
        <React.Fragment>
            <Header/>
            <Container>

              asdf
              <br/>
              <br/>

              <a href='/'> Zurück zur Startseite </a>
              <br/>
              <br/>
              <br/>

            </Container>
            <Footer/>
        </React.Fragment>
    );
};

export default withRouter(DatenschutzerklärungContainer);
