import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch} from 'react-router-dom';

/** Container components */
import PublicHomeContainer from '../Containers/PublicHomeContainer';
import ImpressumContainer from '../Containers/ImpressumContainer';
import DatenschutzerklärungContainer from '../Containers/DatenschutzerklärungContainer';
import PublicRoutes from './PublicRoutes';

class AppRouter extends React.Component {
  render() {
    return (
      <Suspense fallback={null}>
      <Router>
        <Switch>

          <PublicRoutes exact={true} path="/" component={PublicHomeContainer} />
          <PublicRoutes exact={true} path="/impressum" component={ImpressumContainer} />
          <PublicRoutes exact={true} path="/dse" component={DatenschutzerklärungContainer} />
        </Switch>
      </Router>
      </Suspense>
    );
  }
}

export default AppRouter;
