import React, {lazy} from "react";
import {withRouter} from 'react-router';

import Footer from "../Components/Footer";
import Header from "../Components/Header";

const Container = lazy(() =>
    import ("../common/Container"));


const ImpressumContainer = () => {

    return (
        <React.Fragment>
            <Header/>
            <Container>
                <h2>Impressum</h2>

                <h4>Angaben gem&auml;&szlig; &sect; 5 TMG</h4>
                <p>Mario Cla&szlig;en<br/>
                    Ringstr. 28<br/>
                    50765 K&ouml;ln</p>

                <h4>Kontakt</h4>
                <p>
                    E-Mail: info@pruver.de</p>

                <h4>Redaktionell verantwortlich</h4>
                <p>Mario Cla&szlig;en<br/>
                    Ringstr. 28<br/>
                    50765 K&ouml;ln</p>


                    <a href='/'> Zurück zur Startseite </a>
                    <br/>
                    <br/>
                    <br/>
            </Container>
            <Footer/>
        </React.Fragment>
    );
};

export default withRouter(ImpressumContainer);
