import React, { useState, Fragment, lazy } from "react";
import { Row, Col, Drawer } from "antd";
import { CSSTransition } from "react-transition-group";
import { withRouter } from 'react-router';

import Logo from '../Img/logo_schrift.png';

import * as S from "./styles";

const Button = lazy(() =>
  import ("../../common/Button"));

const Header = ({ t, history, showPortalHeader }) => {
  const [isNavVisible] = useState(false);
  const [isSmallScreen] = useState(false);
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {

    const nextPath = (path) => {
      history.push(path);
    };

    if (showPortalHeader) {
      return (
        <Fragment>
          <S.CustomNavLinkSmall
            style={{ width: "180px" }}
            onClick={() => nextPath('/')}
          >
            <S.Span>
              <Button>{t("Back to Homepage")}</Button>
            </S.Span>
          </S.CustomNavLinkSmall>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <S.CustomNavLinkSmall
          style={{ width: "180px" }}
        >
          <S.Span>
            <Button ><a style={{color:'white'}} href="mailto:info@pruver.de">Kontaktiere uns</a></Button>
          </S.Span>
        </S.CustomNavLinkSmall>
      </Fragment>
    );
  };

  return (
    <S.Header>
      <S.Container>
        <Row type="flex" justify="space-between" gutter={20}>
          <S.LogoContainer to="/" aria-label="homepage">
            <img alt ={"Logo"} src={Logo} style={{width: '200px', height: '50px'}}/>
          </S.LogoContainer>
          <S.NotHidden>
            <MenuItem />
          </S.NotHidden>
          <S.Burger onClick={showDrawer}>
            <S.Outline />
          </S.Burger>
        </Row>
        <CSSTransition
          in={!isSmallScreen || isNavVisible}
          timeout={350}
          classNames="NavAnimation"
          unmountOnExit
        >
          <Drawer closable={false} visible={visible} onClose={onClose}>
            <Col style={{ marginBottom: "2.5rem" }}>
              <S.Label onClick={onClose}>
                <Col span={12}>
                  <S.Menu>Menu</S.Menu>
                </Col>
                <Col span={12}>
                  <S.Outline padding="true" />
                </Col>
              </S.Label>
            </Col>
            <MenuItem />
          </Drawer>
        </CSSTransition>
      </S.Container>
    </S.Header>
  );
};

export default withRouter((Header));
