import React, { lazy, Fragment } from "react";
import { Row } from "antd";
import Fade from "react-reveal/Fade";

import * as S from "./styles";
import {Link} from "react-router-dom";

const Container = lazy(() =>
  import ("../../common/Container"));

const Footer = () => {

  return (
    <Fragment>
      <Fade bottom>

        <S.Extra>
          <Container border="true">
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              style={{ paddingTop: "3rem" }}
            >

              <S.FooterContainer>
                <Link to={`/impressum`}>Impressum</Link>
                <Link to={`/dse`}>Datenschutzerklärung</Link>
              </S.FooterContainer>
            </Row>
          </Container>
        </S.Extra>
      </Fade>
    </Fragment>
  );
};

export default (Footer);
