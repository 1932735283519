import * as React from 'react';
import {Route} from 'react-router-dom';


const PublicRoutes = ({component: Component, onlyLogout, ...rest}) => {

      return (
        <Route
            {...rest}
            render={props => {
                return (<Component {...props}/>);
            }
            }
        />
    );
};

export default PublicRoutes;
